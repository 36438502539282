import {WIDGET_TYPE} from '../../commons/constants/settings'
import {DemoEvents} from '../../commons/types/state'

export const getDemoEvent = (demoEvents: DemoEvents, slug: string) => {
  let demoEventNumber = Number(slug.replace('demo', '')) || 0
  demoEventNumber = Math.max(Math.min(demoEventNumber, 3), 0)
  if (demoEventNumber === 0) {
    return demoEvents[WIDGET_TYPE.SINGLE][0]
  } else {
    return demoEvents[WIDGET_TYPE.LIST][demoEventNumber - 1]
  }
}
