import {getCouponDiscountCode} from '@wix/wix-events-commons-statics'
import {PlanInfo} from '@wix/wix-events-commons-statics/dist/types/exported-types'
import {RegFormData, GetState, ReservationState, StoreExtraArgs} from '../types'
import {callAPI, createActions} from '../../commons/actions/data-action-helper'
import {onlyFreeTicketsSelected, getInvoice, isOrderCompleted} from '../selectors/placed-order'
import {getCurrentMemberDetails} from '../selectors/current-member-details'
import {getSelectedPaidPlan} from '../selectors/paid-plans'
import {extractFormData} from '../utils/api-data-mapper'
import {getAgreementToken} from '../selectors/policies'
import {placeOrderButtonClicked} from './payment'
import {navigateToOrder} from './navigation'

export const PLACE_ORDER = createActions('PLACE_ORDER')
export const UPDATE_ORDER = createActions('UPDATE_ORDER')
export const CANCEL_ORDER = 'CANCEL_ORDER'

export const cancelOrder = () => (dispatch: Function, getState: GetState) => {
  if (isOrderCompleted(getState().placedOrder.order)) {
    return dispatch({type: CANCEL_ORDER})
  } else {
    return Promise.resolve()
  }
}

export const placeOrder = (eventId: string, slug: string, buyer: RegFormData, guests?: RegFormData[]) => (
  dispatch: Function,
  getState: GetState,
  {fedopsLogger}: StoreExtraArgs,
) => {
  fedopsLogger.checkoutStarted()
  const state = getState()
  const {membersAreaEnabled} = state
  const couponCode = getCouponDiscountCode(getInvoice(state))
  const memberData = getCurrentMemberDetails(state)
  const memberId = membersAreaEnabled && memberData ? memberData.id : undefined
  const selectedPlan = getSelectedPaidPlan(state) || ({} as PlanInfo)
  const {benefitId, planOrderId} = selectedPlan
  const policyAgreementToken = getAgreementToken(state)

  guests = guests ? guests.map(extractFormData) : null

  return dispatch(
    callAPI(PLACE_ORDER, {eventId, buyer, guests, couponCode, memberId, planOrderId, benefitId, policyAgreementToken}),
  ).then(response => {
    const order = response.order

    if (onlyFreeTicketsSelected(order)) {
      dispatch(placeOrderButtonClicked())
    }

    dispatch(postPlaceOrder(order))

    return response
  })
}

const postPlaceOrder = (order: wix.events.ticketing.Order) => (
  dispatch: Function,
  getState: GetState,
  {fedopsLogger}: StoreExtraArgs,
) => {
  fedopsLogger.checkoutEnded()
  const totalPrice = Number(order.totalPrice.amount)

  if (!totalPrice) {
    dispatch(navigateToOrder(order.reservationId, ReservationState.SUCCESS))
  }
}

export const updateOrder = (eventId: string, orderNumber: string, buyer: RegFormData, guests?: RegFormData[]) =>
  callAPI(UPDATE_ORDER, eventId, orderNumber, buyer, guests)
