export interface RsvpError extends Error {
  payload: {
    metadata: RsvpErrorMetadata
  }
}

export interface RsvpErrorMetadata {
  error_key?: RsvpErrorKey
  max_rsvp_size?: string
  member_already_registered?: boolean
}

export enum RsvpErrorKey {
  RSVP_CLOSED = 'RSVP_CLOSED',
  GUEST_LIMIT_REACHED = 'GUEST_LIMIT_REACHED',
  WAITING_LIST_UNAVAILABLE = 'WAITING_LIST_UNAVAILABLE',
  GUEST_ALREADY_REGISTERED = 'GUEST_ALREADY_REGISTERED',
  MEMBER_ALREADY_REGISTERED = 'MEMBER_ALREADY_REGISTERED',
  UNEXPECTED_RSVP_RESPONSE = 'UNEXPECTED_RSVP_RESPONSE',
  UNKNOWN_RSVP_ERROR = 'UNKNOWN_RSVP_ERROR',
}
