import {
  IAppData,
  IPlatformAPI,
  IWidgetControllerConfig,
  IWixAPI,
} from '@wix/native-components-infra/dist/src/types/types'
import {EVENTS_DETAILS_PAGE_ID, EVENTS_MEMBERS_WIDGET_ID, EVENTS_WIDGET_ID} from '@wix/wix-events-commons-statics'
import {ExperimentsBag} from '@wix/wix-experiments'
import {isPageOOIEnabled, isPageOOIRegisteredEnabled} from './commons/selectors/experiments'
import {getExperimentsByScope} from './commons/utils/experiments'
import {createDetailsPageController} from './details-page/controllers'
import {createMembersPageController as createMembersAppController} from './members-page/controllers/members-page'
import {createWidgetController} from './widget/controllers'

let experiments: ExperimentsBag
let eventsExperiments: ExperimentsBag

export const initAppForPage = async (initParams: IAppData, apis: IPlatformAPI, wixCodeApi: IWixAPI) => {
  const [exp, eventExp] = await Promise.all([
    getExperimentsByScope(wixCodeApi, 'client-spec-map'),
    getExperimentsByScope(wixCodeApi, 'wix-events-ui'),
  ])
  experiments = exp
  eventsExperiments = eventExp
}

export const createControllers = (controllerConfigs: IWidgetControllerConfig[]) =>
  controllerConfigs.map(c => {
    if (c.type === EVENTS_WIDGET_ID) {
      return createWidgetController(c, eventsExperiments)
    } else if (c.type === EVENTS_MEMBERS_WIDGET_ID) {
      return createMembersAppController(c)
    } else if (
      c.type === EVENTS_DETAILS_PAGE_ID &&
      (isPageOOIRegisteredEnabled(experiments) || isPageOOIEnabled(experiments) || runningOOIPageSled(c))
    ) {
      return createDetailsPageController(c)
    }
    return undefined
  })

const runningOOIPageSled = (config: IWidgetControllerConfig) =>
  Boolean((config?.platformAPIs?.bi as any)?.pageUrl?.includes('sledEventsPageOOI=1'))
