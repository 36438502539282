import {getEventId} from '@wix/wix-events-commons-statics'
import {openDialogModal} from '../../commons/services/modal'
import {GetState} from '../types/state'

export const TOGGLE_AGREE_POLICIES = 'TOGGLE_AGREE_POLICIES'

export const toggleAgreePolicies = () => ({
  type: TOGGLE_AGREE_POLICIES,
})

export const openPolicyModal = (policyId: string) => (dispatch: Function, getState: GetState) => {
  const state = getState()
  dispatch(
    openDialogModal({
      type: 'policy',
      params: {
        policyId,
        eventId: getEventId(state.event),
      },
    }),
  )
}
