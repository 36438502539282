import {IUser, IWidgetControllerConfig} from '@wix/native-components-infra/dist/es/src/types/types'
import {UPDATE_SITE_SETTINGS} from '../../commons/actions/site-settings'
import {SiteSettingsSettings} from '../../commons/types/state'
import {Api as BaseApi} from '../../commons/utils/api'
import {getLanguage, getViewMode} from '../../commons/utils/wix-code-api'
import {UPDATE_COMPONENT, UPDATE_COMPONENT_DRAFT} from '../actions/component'
import {CREATE_EVENT} from '../actions/event'
import {LOAD_MEMBERS_FOR_EVENTS, PROMPT_LOGIN} from '../actions/members'

export class Api extends BaseApi {
  constructor(controller: IWidgetControllerConfig, private V2WidgetData) {
    super(controller)

    this.registrar = {
      [UPDATE_COMPONENT.NAME]: this.updateComponent,
      [UPDATE_COMPONENT_DRAFT.NAME]: this.updateComponentDraft,
      [UPDATE_SITE_SETTINGS.NAME]: this.updateSiteSettings,
      [CREATE_EVENT.NAME]: this.createEvent,
      [PROMPT_LOGIN.NAME]: this.promptLogin,
      [LOAD_MEMBERS_FOR_EVENTS.NAME]: this.getGuestLists,
    }
  }

  getAppData = (members: boolean, paidPlans: boolean, responsive = false) => {
    const {compId, wixCodeApi} = this.controller
    const language = getLanguage(wixCodeApi)
    const viewMode = getViewMode(wixCodeApi)
    if (this.V2WidgetData) {
      const url = `/html/v2/widget-data?compId=${compId}&locale=${language}&viewMode=${viewMode}&members=${members}&paidPlans=${paidPlans}${
        responsive ? `&responsive=${responsive}` : ''
      }`

      return this.api.get(url)
    } else {
      const url = `/html/widget-data?instance=${this.getInstance()}&compId=${compId}&locale=${language}&viewMode=${viewMode}&members=${members}&paidPlans=${paidPlans}${
        responsive ? `&responsive=${responsive}` : ''
      }`

      return this.api.get(this.appendLinguisticParams(this.appendPetriOvr(url)))
    }
  }

  updateSiteSettings = (settings: SiteSettingsSettings) => {
    return this.api.put(`/web/site-settings`, {settings})
  }

  updateComponentDraft = (type: string, component: wix.events.editor.WebComponentConfig, members: boolean = false) => {
    const {compId} = this.controller
    return this.api.put(`/web/component/${compId}/draft?members=${members}`, {component})
  }

  updateComponent = (component: wix.events.editor.WebComponentConfig) => {
    const {compId} = this.controller
    return this.api.put(`/web/component/${compId}`, {component})
  }

  createEvent = (eventData: wix.events.EventData, ADI: boolean): Promise<{event: wix.events.Event; ADI: boolean}> => {
    return this.api.post('/adi/events', eventData).then(event => ({event, ADI}))
  }

  getGuestLists = (eventIds: string[]): Promise<GuestLists> =>
    this.api.get(`/web/members?eventIds=${JSON.stringify(eventIds)}`)

  promptLogin = (lang: string) => {
    return new Promise(async (resolve, reject) => {
      try {
        await this.controller.wixCodeApi.user.promptLogin({
          mode: 'login',
          lang,
        })
        resolve(true)
      } catch (e) {
        if (typeof e !== 'string') {
          reject(e)
          return
        }
        resolve(false)
      }
    })
  }

  onLogin = (handler: LoginHandler) => {
    this.controller.wixCodeApi.user.onLogin(handler)
  }
}

export type LoginHandler = (user: IUser) => void
