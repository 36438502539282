import {State, RegFormData} from '../types'
import {FormStep} from '../constants/constants'
import {isOrderCompleted} from './placed-order'

export const getCurrentStep = (state: State) => state.checkout.currentStep

export const isValidPaymentAdded = (state: State) => state.checkout.validPaymentAdded

export const getBuyerDetails = (state: State) => state.checkout.buyerDetails

export const getTicketsDetails = (state: State) => state.checkout.ticketsDetails

export const getTicketDetails = (state: State, index: number) => state.checkout.ticketsDetails[index]

export const getUseBuyerDetails = (state: State) => state.checkout.useBuyerDetails

export const isBuyerDetailsStep = (state: State) => getCurrentStep(state) === FormStep.BuyerDetails

export const getExpandedTicketIndex = (state: State) => state.checkout.expandedTicketIndex

export const isStepVisible = ({step, invoice, assignedTicketsEnabled, hasPolicies}: IsStepVisibleArgs) => {
  switch (step) {
    case FormStep.TicketsDetails:
      return assignedTicketsEnabled
    case FormStep.Policies:
      return hasPolicies
    case FormStep.Payment:
      return Number(invoice.grandTotal.amount) > 0
    default:
      return true
  }
}

export const isStepCompleted = ({
  step,
  placedOrder,
  assignedTicketsEnabled,
  buyerDetails,
  validPaymentAdded,
  agreedWithPolicies,
}: IsStepCompletedArgs) => {
  switch (step) {
    case FormStep.BuyerDetails:
      return assignedTicketsEnabled ? Boolean(buyerDetails) : isOrderCompleted(placedOrder)
    case FormStep.TicketsDetails:
      return isOrderCompleted(placedOrder)
    case FormStep.Policies:
      return agreedWithPolicies
    case FormStep.Payment:
      return validPaymentAdded
    default:
      return false
  }
}

interface IsStepVisibleArgs {
  step: string
  invoice: wix.events.ticketing.Invoice
  assignedTicketsEnabled: boolean
  hasPolicies: boolean
}

interface IsStepCompletedArgs {
  step: string
  placedOrder: wix.events.ticketing.Order
  assignedTicketsEnabled: boolean
  buyerDetails: RegFormData
  validPaymentAdded: boolean
  agreedWithPolicies: boolean
}
