import Ticket = wix.events.ticketing.TicketDefinition
import {SelectedTickets} from '../types'

export const calculateSubPrice = (price: number, quantity: number) => (Math.round(price * 100) * quantity) / 100

const getSelectedTicketsCount = (id: string, selectedTickets: SelectedTickets) => selectedTickets[id] || 0

const getSelectedAndNotFreeTickets = (tickets: Ticket[], selectedTickets: SelectedTickets) =>
  tickets.filter(({free, id}) => !free && !!getSelectedTicketsCount(id, selectedTickets))

export const calculateTicketsTotalPrice = (tickets: Ticket[], selectedTickets: SelectedTickets) =>
  getSelectedAndNotFreeTickets(tickets, selectedTickets).reduce(
    (sum: number, {price: {amount}, id}: Ticket) => sum + Number(amount) * getSelectedTicketsCount(id, selectedTickets),
    0,
  )
